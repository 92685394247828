import React from "react";
export default function Banner() {
  return (
    <article className="sponsorbanner">
      <div className="sponsorbanner_inner">
        <h2 className="sponsorbanner_heading">
          Trusted <br /> By
        </h2>
        <img
          src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217866/Leagues%20About%20Images/phillips_logo_fkokip.webp"
          alt="phillips logo"
          className="sponsorbanner_image"
        />
        <img
          src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/riotgames_logo_g7uxnu.webp"
          alt="riotgames logo"
          className="sponsorbanner_image"
        />
        <img
          src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/telia_logo_tn8jg5.webp"
          alt="telia logo"
          className="sponsorbanner_image"
        />
        <img
          src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217869/Leagues%20About%20Images/steelseries_logo_edlnbl.webp"
          alt="steelseries logo"
          className="sponsorbanner_image"
        />
      </div>
    </article>
  );
}
