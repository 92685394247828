import React, { useEffect, useState, useContext } from "react";
import { TeamMemberListItem, List } from "@common";
import AddTeamMemberModal from "@modals/AddTeamMemberModal/AddTeamMemberModal";
import Axios from "axios";
import { getGameBackground } from "../../../../../../utils/additional-helpers";
import { toast } from "react-toastify";
import useTranslated from "../../../../../../helpers/translationHelper";
import { AppContext } from "../../../../../../context/AppContext";
import CreatePlayerModal from "@modals/CreatePlayerModal/CreatePlayerModal";

const AllMembers = ({ team, isOwner, isManager,isCoach,setTeamCaptain }) => {
  const { user: logedIn } = useContext(AppContext);
  const [pendingInvites, setPendingInvites] = useState(isOwner ? team.pendingInvites : []);
  const [members, setMembers] = useState([]);
  const [existedmembers, setExistedMembers] = useState([]);
  const [orgmembers, setOrgMembers] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [availableNewRoles, setAvailableNewRoles] = useState(team.game.configuration.playerTypes.map(({ name }) => "Player:" + name));
  const [coachTypes, setCoachTypes] = useState(team.game.configuration.coachTypes ? team.game.configuration.coachTypes : []);
  const [subTypes, setSubTypes] = useState(team.game.configuration.subTypes ? team.game.configuration.subTypes : []);
  const [showCreatePlayer, setShowCreatePlayer] = useState(false)

  const lableViewProfile=useTranslated("id_team_page_(members)_4")
  const lableDelete=useTranslated("id_team_page_(members)_5")



  const lableAddPlayer=useTranslated("id_team_page_(members)_3")
  const lableSubstitues=useTranslated("id_team_page_(members)_6")
  const lableCoaches=useTranslated("id_team_page_(members)_7")
  const lableStaff=useTranslated("id_team_page_(members)_8")
  const lableCurrentrole=useTranslated("id_team_page_(members)_9")
  const lable=useTranslated("id_team_page_(members)_10")

  

  useEffect(() => {
    if (team.members) {
      setExistedMembers([...existedmembers, ...team.members]);
    }
    if (team.organisation && team.organisation.owners) {
      setExistedMembers([...existedmembers, ...team.organisation.owners]);
      setOrgMembers([...team.organisation.owners]);
    }
    setAllMembers(team.members.filter((member) => member?.role?.includes("Player")));
    setMembers(team.members.map((member) => (member?.role === "Player:SUB" ? { ...member, role: "Player:SUB:No Specification" } : member)));
  }, [team]);

  const getPlayerPositionData = (role = "") => {
    if (role.includes("SUB")) role = role + ":No Specification";

    const roleSplit = role.split(":")[1];
    const subRoleSplit = role.split(":")[2];
    const subPlayer = (team.game || {}).configuration.playerTypes.find(({ name }) => name === "SUB");

    if (roleSplit === "SUB") {
      if ((team.game || {}).configuration?.subTypes) {
        let subRole = (team.game || {}).configuration?.subTypes?.find(({ name }) => name === subRoleSplit);

        let subObj = {
          name: "SUB:" + subRole.name,
          icon: subPlayer.icon,
          numberOfPlayers: subPlayer.numberOfPlayers ? subPlayer.numberOfPlayers : 0,
        };

        return subObj;
      }
      return {
        name: "SUB:No Specification",
        icon: subPlayer.icon,
        numberOfPlayers: subPlayer.numberOfPlayers ? subPlayer.numberOfPlayers : 0,
      };
    } else {
      if (roleSplit !== "SUB") return (team.game || {}).configuration.playerTypes.find(({ name }) => name === roleSplit);
      return false;
    }
  };

  const getCoachPositionData = (role = "") => {
    if (role === "Coach") role = role + ":No Specification";

    const roleSplit = role.split(":")[1];

    if (roleSplit && (team.game || {}).configuration?.coachTypes)
      return (team.game || {}).configuration?.coachTypes?.find(({ name }) => name === roleSplit);
    return { name: "No Specification" };
  };

  const afterInviteMembers = (invites) => {
    setPendingInvites(invites.map((invite) => ({ ...invite, type: "invite" })));
  };

  const onChangeRole = async (id, newRole) => {
    if (newRole === "Player:SUB") newRole = "Player:SUB:No Specification";

    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/members/${id}`, { newRole });
      setMembers(members.map((member) => (member._id === id ? { ...member, role: newRole } : member)));
      setAllMembers((prevState) => prevState.map((member) => (member._id === id ? { ...member, role: newRole } : member)));

      return toast.success("Successfully changed role!");
    } catch (error) {
      return toast.error("There was a problem changing role");
    }
  };

  const onDeleteMember = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/members/${id}`);
        toast.success("Successfully deleted member");
        setMembers((members) => members.filter(({ _id }) => _id !== id));
      } catch (e) {
        toast.error("Could not delete member");
      }
    }
  };

  const onDeleteInvite = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/invites/${id}`);
        toast.success("Successfully deleted invite");
        setPendingInvites((invites) => invites.filter(({ _id }) => _id !== id));
      } catch (e) {
        toast.error("Could not delete invite");
      }
    }
  };

  const onCreate = (data, type) => {
    logedIn.dispatch({ type: "update", payload: { [type]: [...logedIn.state[type], data] } });
  };

  const playerTypes = (team.game || {}).configuration.playerTypes.map(({ name }) => `Player:${name}`);
  const substituteTypes = (team.game || {}).configuration?.subTypes
    ? (team.game || {}).configuration?.subTypes?.map(({ name }) => name)
    : ["No Specification"];

  const memberSections = [
    { name: "Pending Invites", members: pendingInvites.map((invite) => ({ ...invite, type: "invite" })) },
    {
      name: lableAddPlayer/* "Players" */,
      members: members.filter(({ role }) => role && playerTypes.includes(role) && !role.toLowerCase().includes("sub")),
    },
    {
      name: lableSubstitues/* "SUBSTITUTES" */,
      members: members.filter(({ role }) => role && substituteTypes?.includes(role.split(":")[2]) && role.toLowerCase().includes("sub")),
    },
    { name: lableCoaches/* "Coaches" */, members: members.filter(({ role }) => role && role.includes("Coach")) },
    { name: "Others", members: members.filter(({ role }) => role === "") },
    {
      name: lableStaff/* "Staff" */,
      members:
        // members.length > 0 ?
        [
          ...members.filter(({ role }) => ["Manager", "Owner"].includes(role)),
          ...orgmembers.map((owner) => ({ ...owner, role: `${owner?.role || "Owner"} (Organisation)` })),
        ],
      // : [],
    },
  ];

  console.log("MemberSections = ", memberSections)

  // console.log('memberSections-',memberSections)
  // build sort order index for members list (TOP, JUNGLE, MID, BOTTOM, SUPPORT)
  // source: https://stackoverflow.com/a/14872766/3851180
  let ordering = {}, // map for efficient lookup of sortIndex
    sortOrder = ["Player:TOP", "Player:JUNGLE", "Player:MID", "Player:BOT", "Player:SUPPORT", "Player:SUB"];
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  return (
    <div>
      {isOwner && (
        <button className="button wide-primary" onClick={() => setShowAddMember(true)} style={{ backgroundColor: getGameBackground() }}>
          {lableAddPlayer/* Add player, coach or staff */}
        </button>
      )}

      {memberSections.map(
        ({ name, members = [] }, i) =>
          !!members.length && (
            <div className="row mb-4" key={i}>
              <div className="col-2">
                <span className="text-faded uppercase bold">{name}</span>
              </div>
              <div className="col-9">
                <List>
                  {members
                    .sort((a, b) => ordering[a.role] - ordering[b.role])
                    .map((singleMember) => {
                      if (singleMember && singleMember._id) {
                        const { _id, user, player,isCaptain, coach, role, type } = singleMember;
                        if (type === "invite") {
                          return (
                            <TeamMemberListItem
                              key={_id}
                              ind={singleMember?.user?._id}
                              img={user ? user.avatarImage : player ? player.avatarImage : false}
                              userFirstName={user && user.firstName}
                              userLastName={user && user.lastName}
                              title={
                                user
                                  ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}`
                                  : player
                                  ? player.name
                                  : coach
                                  ? coach.name
                                  : false
                              }
                              tagLine={player?.tagLine}
                              subtitle={
                                player
                                  ? `${player.user.firstName}${player.user.nickname ? ` "${player.user.nickname}" ` : " "}${
                                      player.user.lastName
                                    }`
                                  : coach
                                  ? `${coach.user.firstName}${coach.user.nickname ? ` "${coach.user.nickname}" ` : " "}${
                                      coach.user.lastName
                                    }`
                                  : false
                              }
                              position={player ? getPlayerPositionData(role) : false}
                              coachPosition={coach ? getCoachPositionData(role) : false}
                              role={!player && !coach ? role : false}
                              nationality={
                                player ? player.user.nationality : coach ? coach.user.nationality : user ? user.nationality : false
                              }
                              pending={true}
                              actions={[
                                <button
                                  onClick={() => onDeleteInvite(_id)}
                                  className="text-14 primary-link color-danger"
                                  style={{ padding: 0 }}
                                >
                                  {lableDelete/* Delete */}
                                </button>,
                              ]}
                              availableNewRoles={availableNewRoles}
                              allMembers={allMembers}
                              pendingInvites={pendingInvites}
                              team={team}
                              coachTypes={coachTypes}
                              subTypes={subTypes}
                              editableCaptain = {isOwner || isManager || isCoach}
                              isCaptain = {isCaptain}
        
                              isPlayer={player ? true : false}
                              playerId={player && player?._id}
                              setTeamCaptain={setTeamCaptain}
                            />
                          );
                        } else {
                          return (
                            <TeamMemberListItem
                              key={_id}
                              ind={singleMember?.user?._id}
                              img={user ? user.avatarImage : player ? player.avatarImage : false}
                              userFirstName={user && user.firstName}
                              userLastName={user && user.lastName}
                              title={
                                user
                                  ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}`
                                  : player
                                  ? player.name
                                  : coach
                                  ? coach.name
                                  : false
                              }
                              tagLine={player?.tagLine}
                              subtitle={
                                player
                                  ? `${player.user.firstName}${player.user.nickname ? ` "${player.user.nickname}" ` : " "}${
                                      player.user.lastName
                                    }`
                                  : coach
                                  ? `${coach.user.firstName}${coach.user.nickname ? ` "${coach.user.nickname}" ` : " "}${
                                      coach.user.lastName
                                    }`
                                  : false
                              }
                              position={player ? getPlayerPositionData(role) : false}
                              coachPosition={coach ? getCoachPositionData(role) : false}
                              role={!player && !coach ? role : false}
                              nationality={
                                player ? player.user.nationality : coach ? coach.user.nationality : user ? user.nationality : false
                              }
                              editable={isOwner || isManager}
                              onChangeRole={(newRole) => onChangeRole(_id, newRole)}
                              actions={[
                                <a
                                  href={
                                    player
                                      ? "/game-profile/" + player._id
                                      : coach
                                      ? "/coaches/" + coach._id
                                      : user
                                      ? "/players/" + user._id
                                      : false
                                  }
                                  className="text-14 primary-link"
                                >
                                  {lableViewProfile/* View profile */}
                                </a>,
                                isOwner && role !== "Owner (Organisation)" && role !== "Manager (Organisation)" && (
                                  <button
                                    onClick={() => onDeleteMember(_id)}
                                    className="text-14 primary-link color-danger"
                                    style={{ padding: 0 ,marginLeft:'140px'}}
                                  >
                                    {lableDelete/* Delete */}
                                  </button>
                                ),
                              ]}
                              availableNewRoles={availableNewRoles}
                              allMembers={allMembers}
                              pendingInvites={pendingInvites}
                              team={team}
                              coachTypes={coachTypes}
                              subTypes={subTypes}
                              editableCaptain = {isOwner || isManager || isCoach}
                              isPlayer={player ? true : false}
                              isCaptain = {isCaptain}
                              playerId={player && player?._id}
                              setTeamCaptain={setTeamCaptain}
                            />
                          );
                        }
                      }
                    })}
                </List>
              </div>
            </div>
          )
      )}

      <AddTeamMemberModal
        open={showAddMember}
        onClose={() => setShowAddMember(false)}
        team={team}
        members={existedmembers}
        pendingInvites={pendingInvites}
        afterAdd={afterInviteMembers}
        isManager={isManager}
        isOwner={isOwner}
        allMembers={allMembers}
      />
      <CreatePlayerModal
        open={showCreatePlayer}
        onClose={() => setShowCreatePlayer(false)}
        afterCreate={(player) => onCreate(player, "players")}
      />
    </div>
  );
};

export default AllMembers;
