import React from 'react';
import { Link } from "react-router-dom";
export default function Banner() {
  return (
    <article className="banner">
      <div className="banner_inner">
        <div className="banner_image_container">
          <img
            className="banner_image image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/mundo_bh0rcl.webp"
            alt="LoL - Dr. Mundo"
          />
        </div>

        <div className="banner_content">
          <h2 className="banner_content_heading">Begin Now</h2>
          <p className="banner_content_text">
            Whether you host tournaments or want to play esports Leagues.gg is your new home. Head over to the platform and sign-up or join
            our discord communities and talk with us.
          </p>
          <Link to="/" className="banner_content_btn">
            Go to platform
          </Link>
        </div>
        <div className="banner_image_container">
          <img
            className="banner_image image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217869/Leagues%20About%20Images/counter-terrorist_bidkjh.webp"
            alt="counter strike - ct"
          />
        </div>
      </div>
    </article>
  );
}