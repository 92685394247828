import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const OauthCallback = () => {
    const location = useLocation();
    console.log('location', location)

    const getQueryParams = (query) => {
        return query
          ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split('&')
              .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
              )
          : {};
      };


  async function authorize() {
    const params = getQueryParams(location.search);
    console.log('fetching: ', `https://api-core.leagues.gg/oauth2-callback?code=${params.code}&iss=${params.iss}&state=${params.state}`)
    fetch(`https://api-core.leagues.gg/oauth2-callback?code=${params.code}&iss=${params.iss}&state=${params.state}`)
  }
  useEffect(async () => {
    await authorize()
  }, [])

};
