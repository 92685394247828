import React from "react";
import { MirrorText, Icon } from "@common";
import Banner from "./Banner/Banner";
import SponsorBanner from "./SponsorBanner/SponsorBanner";

const About = () => {
  return (
    <section>
      <div className="video_container">
        <video
          autoPlay
          loop
          muted
          src="https://res.cloudinary.com/dwithnln1/video/upload/v1695217874/Leagues%20About%20Images/leagues-video_xxi65w.mp4"
        />
      </div>
      <div className="about_container">
        <div className="icon-cross">
          <Icon name="cross" />
        </div>

        <div className="icon-play">
          <Icon name="play" />
        </div>
        <div className="icon-play-2">
          <Icon name="play" />
        </div>

        <div className="about">
          <MirrorText text={["l34gu3s.gg", "Esp0rt"]} />
          <h1 className="about_heading">
            Developed By
            <span className="about_heading_highlighted"> Organisers</span>
            <br /> For<span className="about_heading_highlighted"> Organisers</span>
          </h1>
          <article className="about_article">
            <div className="about_article_texts">
              <h2 className="about_article_heading">
                01. Our <span className="about_article_heading_highlighted">Mission</span>
              </h2>
              <p>
                We strive to be the platform for resolving everyday tasks for any esports organisation and player. It should be as easy as
                possible to organise esports and have the best playing experience. Our mission is to connect all aspects of playing and
                consuming esport in one platform. Esports start with the teams.
              </p>
            </div>
            <div className="about_article_images">
              <img
                className="about_article_image_laptop"
                src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/leagues-laptop_sc7t1t.webp"
                alt="about leagues asset 1"
              />
              <img
                className="about_article_image_screen"
                src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217870/Leagues%20About%20Images/leagues-screen_xpvb85.webp"
                alt="about leagues asset 2"
              />
            </div>
          </article>
          <article className="about_second_article">
            <div className="about_article_texts">
              <h2 className="about_article_heading">
                02. <span className="about_article_heading_highlighted">Who</span> are we?
              </h2>
              <p>
                We are a global organisation based in Copenhagen, Denmark. Originally, Leagues was founded in 2017 running the largest
                danish League of Legends league. Later running CS:GO leagues all around Europe. We have worked with A-Level brands such as
                RedBull, Telia, Riot Games and SteelSeries doing basically everything to make an esports tournament possible. We are now
                using all this knowledge in developing a platform for organisers, by organisers, to make it butter smooth to organise
                tournaments and handle a team.
              </p>
            </div>
          </article>
        </div>
      </div>
      <Banner />
      <div className="about_container">
        <div className="icon-cross-2">
          <Icon name="cross" />
        </div>

        <div className="icon-play-3">
          <Icon name="play" />
        </div>
        <div className="icon-play-4">
          <Icon name="play" />
        </div>

        <div className="about">
          <article className="about_article align-center gap">
            <div className="about_article_texts larger-width">
              <h2 className="about_article_heading about_article_heading_highlighted">Organisers</h2>
              <p>
                We know the struggle of organising esports. Prizepool chaos, using multiple platforms and having enormous excel sheets. With
                Leagues, you will eliminate all these issues and empower yourself to grow.
              </p>
            </div>
            <img
              className="about_article_image no-aspect-ratio"
              src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/leagues_laptop2_tgl9gj.webp"
              alt="leagues laptop"
            />
          </article>
          <article className="about_article spacing align-center gap">
            <video
              controls
              src="https://res.cloudinary.com/dwithnln1/video/upload/v1695217874/Leagues%20About%20Images/leagues-video_xxi65w.mp4"
              onLoadStart={(e) => {
                e.target.volume = 0.33;
              }}
              className="about_article_video"
            />
            <div className="about_article_texts larger-width">
              <h2 className="about_article_heading about_article_heading_highlighted">Teams</h2>
              <p>
                Whether you are new, casual or pro, Leagues helps you save time on excel sheets and bad servers. Manage your team, track
                data and do performance analysis. All on one platform.
              </p>
            </div>
          </article>
        </div>
      </div>
      <SponsorBanner />
      <div className="about_container">
        <div className="about">
          <article className="about_article align-center gap">
            <div className="about_article_texts larger-width">
              <h2 className="about_article_heading">
                The <span className="about_article_heading_highlighted">Leagues</span> Team
              </h2>
              <p>
                Our team consists of dedicated and passionate nerds and creatives. Other than our office in Søborg, Leagues also owns a
                full-scale broadcasting studio for esports.
              </p>
            </div>
            <img
              className="about_article_image"
              src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/leagues_team_c1wasa.webp"
              alt="Leagues team"
            />
          </article>
          <article className="about_article spacing align-center gap">
            <img
              className="about_article_image"
              src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217868/Leagues%20About%20Images/studio_j2yutd.webp"
              alt="Leagues studio"
            />
            <div className="about_article_texts larger-width">
              <h2 className="about_article_heading">
                Dedicated <span className="about_article_heading_highlighted">Studio</span>
              </h2>
              <p>
                We love good content. So does our partners. Therefore, we make good content.
                <br />
                <br />
                ​Leagues have in Copenhagen an in-house top tier esports studio. We use this studio to highlight exciting matches, players
                and teams. If you have an idea of what we should do next in our studio please contact us!
              </p>
            </div>
          </article>
        </div>
      </div>
      <article className="seen_on_list">
        <h2 className="seen_on_list_heading">Seen on</h2>
        <div className="seen_on_list_grid">
          <img
            className="seen_on_list_grid_image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217866/Leagues%20About%20Images/borsen_logo_fgu1hs.webp"
            alt="Børsen logo"
          />
          <img
            className="seen_on_list_grid_image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/financialtimes_logo_fo6fcd.webp"
            alt="Financial Times logo"
          />
          <img
            className="seen_on_list_grid_image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217867/Leagues%20About%20Images/esi_logo_pv0znv.webp"
            alt="Esports Insider logo"
          />
          <img
            className="seen_on_list_grid_image"
            src="https://res.cloudinary.com/dwithnln1/image/upload/v1695217866/Leagues%20About%20Images/Go_morgen_Denmark_logo_hv74vc.webp"
            alt="Go Morgen Danmark logo"
          />
        </div>
      </article>
      <article className="socials spacing">
        <span>
          email: <a href="mailto:contact@leagues.gg">Contact@leagues.gg</a>
        </span>

        <div className="socials_container">
          <p>Socials:</p>
          <a href="https://discord.gg/WX98KVx" target="_blank" rel="noreferrer">
            <Icon name="discord" />
          </a>
          <a href="https://www.tiktok.com/@leaguesgg_csgo" target="_blank" rel="noreferrer">
            <Icon name="tiktok" />
          </a>
          <a href="https://twitter.com/Leagues_GG" target="_blank" rel="noreferrer">
            <Icon name="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/leaguesgg/" target="_blank" rel="noreferrer">
            <Icon name="linkedin" />
          </a>
        </div>
      </article>
    </section>
  );
};

export default About;
