import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
// import SetPlayersTeam from "./SetPlayersTeam";
import UpcomingGame from "./UpcomingGame";

const GameKeys = () => {
  const { authenticated } = useContext(AppContext);
  const [upcomingGames, setUpcomingGames] = useState([]);
  // const [teamSetPlayers, setTeamSetPlayers] = useState([]);

  const fetchData = async () => {
    await Axios.get(`${process.env.REACT_APP_CORE_API}/api/matchesLiveData/userMatches`)
      .then(( { data }) => {
        setUpcomingGames(data);
      })
      .catch(({ error }) => {
        console.log('error', error)
      })

    // setUpcomingGames(data);
  };

  useEffect(() => {
    if (authenticated) {
      // fetch users upcoming matches
      fetchData();

      // poll every 100k milliseconds for new data (about every 1.3mins)
      setInterval(() => {
        fetchData();
      }, 100000);
    }
  }, [authenticated]);

  return (
    <div className="game-keys-container">
      {upcomingGames.map(({ t1, t2, lolData }) => (
        <UpcomingGame team1={t1.name} team2={t2.name} tournamentCodes={lolData.tournamentCodes} />
      ))}

      {/* <SetPlayersTeam teamName="Your mum" matchCount={3} /> */}
    </div>
  );
};

export default GameKeys;
