import React, { useState, useEffect } from "react";
import Axios from "axios";
import GlitchClip from "react-glitch-effect/core/Clip";
import moment from "moment";
import { Button, Item, SliderItem } from "@common";
import Slider from "../Slider/Slider";
import danish_flag from "@img/default-team-nationality.png";
import league_image from "@img/league-image.png";

const ActiveTournaments = () => {
  const [tournaments, setTournaments] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments`).then(({ data }) => {
      setTournaments(data);
      setLoading(false);
    }).catch(({ error }) => {
      console.log('error fetching tournaments', error)
    });
  }, []);

  return (
    <Slider
      info={
        <div>
          <h1 className="outline">Activ3</h1>
          <h1>Tournaments</h1>
          <Button name="cta-button-transparent">All Tournaments</Button>
        </div>
      }
    >
      {loading
        ? [...Array(5)].map((el, i) => <Item col="12" loading key={i} />)
        : tournaments
            .filter(({ startDate }) => moment() < moment(startDate))
            .map(({ _id, name, logoImage, country, thumbnailImage, teams }, i) => (
              <SliderItem
                key={i}
                title={name ? name : "Challenger Series Denmark"}
                second_line={
                  <React.Fragment>
                    <img
                      src={country ? `https://flagcdn.com/w20/${country.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                      alt="Danish flag"
                      className={"loading"}
                    />
                    {country ? country.name : "Denmark"}
                  </React.Fragment>
                }
                third_line={teams.length === 0 ? "Be the first one to join" : `${teams.length} teams participating`}
                // tag="1. Division"
                image={thumbnailImage ? thumbnailImage : league_image}
                link_to={`/tournament/${_id}`}
              />
            ))}
    </Slider>
  );
};

export default ActiveTournaments;
