import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "@context/AppContext";
import Axios from "axios";
import logo_header from "@img/logo-header.svg";
import Loading from "@common/Loading/Loading";
// import Loading from "../../_common/Loading/Loading";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConnectLOL = ({ afterConnect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { riotId } = useParams();
  const { user } = useContext(AppContext);
  const [inGameName, setInGameName] = useState(null);
  const [game, setGame] = useState(null);
  const [primaryRole, setPrimaryRole] = useState(null);
  const [secondaryRole, setSecondaryRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [regions,setRegions] = useState([{ code: 'NA', name: 'North America' }, { code: 'EUW', name: 'Europe West' }, { code: 'EUN', name: 'Europe Nordic & East' },  { code: 'KR', name: 'Korea' },  { code: 'BR', name: 'Brazil' },  { code: 'JP', name: 'Japan' },  { code: 'RU', name: 'Russia' },  { code: 'OCE', name: 'Oceania' },  { code: 'TR', name: 'Turkey' },  { code: 'LAN', name: 'Latin America North' },  { code: 'LAS', name: 'Latin America South' }]
  );
  const [userRegion, setUserRegion] = useState(null);
  let query = useQuery();
  const onCreate = (data, type) => {
    user.dispatch({ type: "update", payload: { [type]: [...user.state[type], data] } });
  };
  useEffect(() => {
    let _error = query.get("error");
    if (_error) {
      history.push("/");
      toast.error(_error);
    }
  }, []);
  let riotUsername = query.get("riotUsername");
  let playerId= query.get("id");

  const getGames = () => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/game`).then(({ data }) => {
      setRoles(data[0]?.configuration?.playerTypes?.filter(({ name }) => !name.includes("SUB")) || []);
      setGame(data[0]._id);
    }).catch(({ error }) => {
      console.log('error', error)
    });
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      // console.log({ riotUsername, inGameName, primaryRole, secondaryRole });
      let region = regions.find(r=> r.name === userRegion)
      Axios.post(
        `${process.env.REACT_APP_LOL_API}/api/connect`,

        { name: inGameName, primaryRole, secondaryRole, game,playerId,code: "-1",region }
      )
        .then(({ data }) => {
          toast.success("Successfully created leagues of legends player!");
          setLoading(false);

          console.log(data.player);
          onCreate(data.player, "players");

          history.push(`/game-profile/${data.player._id}`);
        })
        .catch((err) => {
          console.log("eeee=", err);
          const msg = err?.response && err?.response.data ? err.response.data.msg : "There was a problem creating player";
          toast.error(msg);
          setLoading(false);
          if (msg !== "In Game Name already registered") {
            history.push(`/`);
          }
        });
    } catch (e) {
      console.log("eeee=", e);
      const msg = e?.response && e?.response.data ? e.response.data.msg : "There was a problem creating player";
      toast.error(msg);
      setLoading(false);
      if (msg !== "In Game Name already registered") {
        history.push(`/`);
      }
    }
  };

  useEffect(() => {
    getGames();
    setInGameName(riotUsername);
  }, [riotUsername]);

  return (
    <div className="global-pnf">
      <div className="container-fluid">
        <div className="area-logo">
          <Link to="#" className="logo-text">
            <img src={logo_header} alt="Leagues logo" />
            {/* <strong className="uppercase">Please</strong> */}
          </Link>
        </div>

        <div>
          <div className="form-row">
            <label>Set your in game name and press next.</label>
            <input className="form-input" value={inGameName} onChange={(e) => setInGameName(e.target.value)} disabled={true} />
          </div>
          <div className="form-row">
            <label htmlFor="primaryRole">Preferred Primary Role</label>
            <select
              id="primaryRole"
              className="form-input"
              value={primaryRole || "_DEFAULT"}
              onChange={(e) => setPrimaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred primary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="secondaryRole">Preferred Secondary Role</label>
            <select
              id="secondaryRole"
              className="form-input"
              value={secondaryRole || "_DEFAULT"}
              onChange={(e) => setSecondaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred secondary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label htmlFor="region">Region</label>
            <select id="region" className="form-input" value={userRegion || "_DEFAULT"} onChange={(e) => setUserRegion(e.target.value)}>
              <option value="_DEFAULT" disabled>
                Choose Region
              </option>
              {regions.map(({ code, name }) => (
                <option key={name} value={name}>
                  {`${code} - ${name}`}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => onSubmit()}
            className="button primary thicc mt-4"
            disabled={loading || secondaryRole === null || primaryRole === null || inGameName === null || inGameName === "" || userRegion === null}
          >
            {loading ? (
              <>
                <Loading width={30} height={30} /> Please wait...
              </>
            ) : (
              "Next"
            )}
          </button>
        </div>

        <div className="area-logo">
          <strong className="uppercase">Do not refresh or close this window.</strong>
        </div>
      </div>
    </div>
  );
};

export default ConnectLOL;
